@import "../../styles/variables";

$border: 1px solid var(--main-gray);

.page {
  &__video-row {
    display: flex;
    width: 100%;
    margin: auto;

    &__video {
      flex-grow: 1;
    }

    &__history {
      margin: 0 0 0 0.5rem;
      // width: 100%;
      width: 10rem;
      min-width: 5rem;

      height: auto;
      max-height: calc(40vw + 6rem);

      overflow: hidden;
    }
  }
  .controlled-player__source {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    // background-color: var(--main-gray);
    // background-color: red;
    height: 5em;
    // width: 90vw;
    height: 80vh;
    max-width: 90vw;
    // max-height: 40vw;
    max-height: calc(50.8vw - 95px);
    border-right: $border;
    border-top: $border;
    border-left: $border;

    &__camera {
      height: 99%;
      width: 99%;
      video {
        height: 100%;
        position: absolute;
        top: 0;
      }
    }
  }
  &--column {
    .controlled-player__source {
      max-height: 50.8vw;
      max-width: 100vw;
    }
  }

  @media only screen and (min-width: 450px) {
    &__video-row {
      width: 90%;
    }
  }

  &__details-row {
    display: flex;
    width: 90%;
    margin: auto;
    flex-direction: column;

    &__history {
      min-width: 9rem;
      height: 15rem;
    }
    &__info {
      margin: 1rem 0 0 0;
      // background-color: red;
      flex-grow: 1;
    }
  }

  &__bottom-spacer {
    height: 5rem;
  }

  @media only screen and (min-width: 600px) {
    &__details-row {
      flex-direction: row;
      // max-height: 15rem;
      &__info {
        margin: 0 0 0 1rem;
      }
    }
  }

  &__footer {
    border-top: $border;
    font-size: 12px;
    width: 90%;
    margin: auto;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__text {
      text-align: center;
      opacity: 0.5;
    }
    &__ko-fi {
      opacity: 0.4;
      margin-top: 10px;
      &:hover {
        opacity: 1;
      }
    }
  }
}
