$border: 1px solid var(--main-gray);

.custom-button{
    background-color: transparent;
    border: $border;
    color: var(--primary-text);
    padding: 0.2rem 0.4rem;

    &:hover{
        background-color: var( --black-alpha-05);
    }
}