@import "../../styles/variables";

$border: 1px solid var(--main-gray);
$box-size-regular: 5rem;
$box-size-small: 4.5rem;

*,
::after,
::before {
  box-sizing: border-box;
}

.controls2 {
  position: relative;
  outline: none;
  display: flex;
  //   flex-wrap: wrap;
  justify-content: center;
  //   width: 90%;
  margin: auto;
  border: $border;
  margin-bottom: 3rem;
  opacity: 1;
  &--disabled {
    &::after {
      content: "Custom shortcuts disabled.";
      position: absolute;
      font-size: 1.5rem;
      align-items: center;
    justify-content: center;
    display: flex;
     background: var(--main-icon);
     color: var(--main-bg-color);
    width: 100%;
    height: 50%;
    }
  }
  &__box {
    height: $box-size-small;
    width: $box-size-small;
    border-right: $border;
    // font-size: 5em;
  }
  &__spacer {
    height: $box-size-small;
    flex-grow: 2;
    // background-color: gray;
    min-width: 0.4rem;
  }

  &__bookmark {
    display: flex;
    flex-wrap: wrap;

    &__add {
      // display: inline-flex;
      width: 50%;
      height: 50%;
      //   background-color: red;
      border-right: $border;
    }
    &__toggle {
      // display: inline-flex;
      width: 50%;
      height: 50%;
      //   background-color: purple;
    }
    &__offset {
      width: 100%;
      height: 50%;
      border-bottom: $border;
    }
    // &__label {
    //   width: 100%;
    // }
  }

  &__source {
  }
  &__record {
    display: flex;
    flex-wrap: wrap;
    border-left: $border;
    &__sound {
      width: 100%;
      height: 50%;
      border-bottom: $border;
    }
    &__mic {
      // flex-grow: 1;
      border-right: $border;
      width: 50%;
      height: 50%;
    }
    &__play {
      // flex-grow: 1;
      width: 50%;
      height: 50%;
    }
  }
  &__rewind {
    display: flex;
    flex-wrap: wrap;

    &__backward {
      width: 50%;
      height: 50%;
      border-right: $border;
    }
    &__forward {
      width: 50%;
      height: 50%;
    }
    &__offset {
      width: 100%;
      height: 50%;
      border-bottom: $border;
    }
  }

  &__utils {
    display: none;
    flex-wrap: wrap;
    &__resize {
      display: none;
      width: 50%;
      height: 0%;
      border-bottom: $border;
      border-right: $border;
    }
    &__camera {
      width: 100%;
      height: 50%;
      border-bottom: $border;
    }
    &__key {
      width: 100%;
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
    }
  }

  @media only screen and (min-width: 450px) {
    &__box {
      height: $box-size-regular;
      width: $box-size-regular;
    }

    &__spacer {
      height: $box-size-regular;
    }

    &__utils {
      display: flex;
    }
  }

  @media only screen and (min-width: 751px) {
    &__utils {
      &__resize {
        display: block;
        height: 50%;
      }
      &__camera {
        width: 50%;
        height: 50%;

      }
      &__key {
        height: 50%;
      }
    }
  }
}

.controls-button {
  height: 100%;
  width: 100%;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    font-size: 1.5rem;
    background-color: transparent;
    &:hover {
      background-color: var(--black-alpha-10);
    }
  }

  svg {
    color: var(--main-icon);
  }
}

.controls-input {
  height: 100%;
  width: 100%;
  &__input {
    display: flex;
    font-size: 1.5rem;

    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    color: var(--primary-text);
    text-align: center;
    background-color: var(--main-bg-color);
    transition: $backgroundTransition;
  }
  svg {
    color: var(--main-icon);
  }
}

.controls-label {
  margin-top: 0.4rem;
  font-size: 1rem;
  width: 100%;

  text-align: center;
}
