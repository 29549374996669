.camera{
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    // &__video{
    // }
    &__zoom{
        position: absolute;
        bottom: 10px;
        width: 80%;
    }

    &__close{
        position: absolute;
        top: 10px;
        right: 10px;
    }
}