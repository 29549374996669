.recording-count{
    display: flex;
    align-items: center;
    font-size: 20px;
    &__dot{
        height: 10px;
        width: 10px;
        background-color: red;
        border-radius: 50%;
        margin-right: 7px ;
        margin-left: 10px ;
    }
}