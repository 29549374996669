.search-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  flex-direction: column;
  &__title {
    margin-left: 20px;
    margin-right: 20px;
  }
  &__search {
    position: relative;
    max-width: 600px;
    width: 80vw;
    &__input {
      width: 100%;
      font-size: 16px;
      padding: 10px 40px 10px 15px;
      outline: none;
      border: 1px solid #cccccc;

      &:focus,
      &:hover {
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
        border-color: transparent;
      }
    }
    &__button {
      position: absolute;
      right: 9px;
      background-color: transparent;
      border: none;
      top: 50%;
      transform: translateY(-50%);
      opacity: 0.6;
      &:hover {
        opacity: 1;
      }
    }
    &__example {
      font-size: 10px;
      color: var(--primary-text);
      position: absolute;
      bottom: -0.7rem;
      left: 0.3rem;
      opacity: 0.6;
      button {
        background: none;
        color: var(--primary-text);
        border: none;
        font-size: 10px;
        padding: 0;
        padding-left: 0.3em;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}
