:root {
  --main-gray: #808080;
  --black: rgb(0, 0, 0);
  --blackInt: 0;
  --blackRGB: var(--blackInt), var(--blackInt), var(--blackInt);
  --main-bg-color-lum: 100%;
  // --main-bg-color:  hsl(0, 0%, var(--main-bg-color-lum));
  --main-bg-color: hsl(210deg 4% var(--main-bg-color-lum));
  --black-alpha-05: rgba(var(--blackRGB), 0.05);
  --black-alpha-10: rgba(var(--blackRGB), 0.1);
  --black-alpha-15: rgba(var(--blackRGB), 0.15);
  --black-alpha-20: rgba(var(--blackRGB), 0.2);
  --black-alpha-30: rgba(var(--blackRGB), 0.3);
  --black-alpha-40: rgba(var(--blackRGB), 0.4);
  --black-alpha-50: rgba(var(--blackRGB), 0.5);
  --black-alpha-60: rgba(var(--blackRGB), 0.6);
  --black-alpha-80: rgba(var(--blackRGB), 0.8);
  // --primary-text: rgb(12, 12, 12);
  // --secondary-text:
  --main-icon-lum: 31%;
  --main-icon: hsl(0, 0%, var(--main-icon-lum));
  --primary-text-lum: 10%;
  --primary-text: hsl(0, 0%, var(--primary-text-lum));

  --speed-slow: 400ms;
  --speed-medium: 200ms;
  --speed-fast: 100ms;

  --title-border-color-lum: 10%;
  --title-border-color: hsl(0deg 0% var(--title-border-color-lum) / 41%);

  color: var(--primary-text);
  html,
  body {
    // min-height: 100%;
    // min-height: 100vh;
    width: 100%;
    height: 100%;
  }
}
