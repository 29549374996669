@import "../../styles/variables";

.bookmark-history {
  //   width: 20rem;
  //   margin: auto;

  &__list {
    margin-top: 0;
    padding: 0;
    // max-height: 10rem;
    height: 90%;

    overflow: auto;
    &__row {
      //   width: 100%;
      list-style-type: none;
      display: flex;
      &--selected {
        background-color: red;
      }
    }
  }
}

.history-button-group {
  position: relative;
  width: 100%;

  button {
    border: none;
    background-color: transparent;
    font-size: 1rem;
    text-align: left;
    padding: 0.2rem;
    color: var(--primary-text);

    &:hover {
      background-color: var(--black-alpha-10);
    }
  }

  &--selected {
    background-color: #ffad2a;
    color: #1a1a1a;
    button{
      color: #1a1a1a;
    }
  }
  &___value {
    width: 100%;
  }
  &___clear {
    position: absolute;
    right: 0;
  }

}

.history-title {
  font-weight: 700;
  padding: 0.2rem 0;
  border-bottom: 1px solid var(--title-border-color);
}
