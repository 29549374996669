@import "../../styles/variables";

.info-panel {
  &__section {
    margin-bottom: 1rem;
    &__utils-content {
      display: flex;
      & > * {
        margin-right: 0.3rem;
        margin-bottom: 0.1rem;
      }
      & :last-child {
        margin-right: 0;
      }
    }
  }
}

.video-source {
  // display: flex;
  // margin: 0.3rem 0 0 0;
  position: relative;
  margin-top: -1px;
  &__input {
    flex-grow: 1;
    border-radius: 0;
    border: 1px solid var(--title-border-color);
    width: 100%;
    color: var(--primary-text);
    background-color: var(--main-bg-color);
    transition: $backgroundTransition;
  }
  &__button {
    margin: 0;
    padding: 0;
    position: absolute;
    right: 4px;
    background-color: transparent;
    border: none;
    top: 50%;
    transform: translateY(-50%);
    svg {
      color: var(--main-icon);
    }
  }
}

.info-title {
  padding: 0.2rem 0;
  border-bottom: 1px solid var(--title-border-color);
  font-weight: 700;
  margin-bottom: 0.5em;
  &--source {
    border: none;
    margin-bottom: 0;
  }
}
